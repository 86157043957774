header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.header__container_logo {
  display: flex;
  justify-content: center;
}

.header__container_logo img {
  width: 385px;
}

/*** CTA ***/
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/*** Header Socials ***/
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/*** Profile Image ***/
.me {
  /* background: linear-gradient(var(--color-primary), transparent); */
  width: 20rem;
  /* height: 30rem; */
  position: absolute;
  left: calc(50% - 10rem);
  margin-top: 4rem;
  border-radius: 50%;
  overflow: hidden;
  /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

/*** Scroll Down Link ***/
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}


@media screen and (max-width: 1024px) {
  .header {
    height: 68vh;
  }
}

@media screen and (max-width: 600px) {
  .header {
    height: 100vh;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}